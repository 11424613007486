<template>
  <div>
    <b-modal
      id="modal-forgot-password"
      ref="modal"
      header-class="headerModal"
      centered
      size="lg"
      :title="$t('ResetPassword')"
      hide-footer
    >
      <b-form />
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px;"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
        >
          {{ $t('Submit') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  // eslint-disable-next-line vue/require-prop-types
  data() {
    return {
    }
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    ...mapActions(['']),

  },
}
</script>

<style lang="scss" scoped>
</style>
