<template>
  <div>
    <b-modal
      id="modal-change-first-password"
      ref="modal"
      header-class="headerModal"
      centered
      size="md"
      :title="$t('ChangePassword')"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          label="Email:"
          label-for="input-1"
        >
          <b-form-input
            id="email"
            v-model="$v.changePassword.email.$model"
            type="email"
            class="form-control1"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('RequiredField') }}</b-form-invalid-feedback>
        </b-form-group>

        <div class="form-group">
          <label>{{ $t('CurrentPass') }}</label>
          <div class="input-group mb-3">
            <b-form-input
              id="password"
              v-model="$v.changePassword.currentPassword.$model"
              :type="showPassword ? 'text' : 'password'"
              class="form-control"
              :state="validateState('currentPassword')"
              aria-describedby="input-1-live-feedback"
            />

            <div class="input-group-append">
              <span
                class="input-group-text"
                style="border-radius:0 5px 5px 0px"
                @click="showPassword = !showPassword"
              >
                <span><i
                  class="fa"
                  :class="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                  aria-hidden="true"
                />
                </span>
              </span>
            </div>
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >{{ $t('RequiredField') }}</b-form-invalid-feedback>
          </div>

          <!-- <div class="valid-feedvack">
            Your password is valid!
          </div>
          <div class="invalid-feedback">
            <span v-if="!$v.password.required">Password is required.</span>
            <span v-if="!$v.password.minLength">{{ $v.password.$params.minLength.min }} characters minimum.</span>
          </div> -->
        </div>
        <div class="form-group">
          <label>{{ $t('NewPassword') }}</label>
          <div class="input-group mb-3">
            <b-form-input
              id="password"
              v-model="$v.changePassword.newPassword.$model"
              :type="showPassword2 ? 'text' : 'password'"
              class="form-control"
              :state="validateState('newPassword')"
              aria-describedby="input-1-live-feedback"
              autocomplete="off"
            />

            <div class="input-group-append">
              <span
                class="input-group-text"
                style="border-radius:0 5px 5px 0px"
                @click="showPassword2 = !showPassword2"
              >
                <span><i
                  class="fa"
                  :class="showPassword2 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                  aria-hidden="true"
                />
                </span>
              </span>
            </div>
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >{{ $t('RequiredField') }}</b-form-invalid-feedback>
          </div>

          <!-- <div class="valid-feedvack">
            Your password is valid!
          </div>
          <div class="invalid-feedback">
            <span v-if="!$v.password.required">Password is required.</span>
            <span v-if="!$v.password.minLength">{{ $v.password.$params.minLength.min }} characters minimum.</span>
          </div> -->
        </div>
        <div class="buttonsEverywhere">
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px;"
            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { validationMixin } from 'vuelidate';
import {
  required, email,
} from 'vuelidate/lib/validators';

// const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;


export default {
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['login'],
  data() {
    return {
      showPassword: false,
      showPassword2: false,
      changePassword: {
        email: this.login.email,
        currentPassword: this.login.password,
        newPassword: '',
      },
    }
  },
  validations: {
    changePassword: {
      email: {
        required,
        email,
      },
      currentPassword: {
        required,
        // passwordRegex(value) {
        //   if (!passwordRegex.test(value)) {
        //     return false;
        //   }
        //   return true;
        // },
      },
      newPassword: {
        required,
        // passwordRegex(value) {
        //   if (!passwordRegex.test(value)) {
        //     return false;
        //   }
        //   return true;
        // },
      },
    },

  },
  watch: {
  },
  mounted() {
  },
  methods: {
    ...mapActions(['logoutUser']),
    validateState(name) {
      const { $dirty, $error } = this.$v.changePassword[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.changePassword.$touch();
      if (this.$v.changePassword.$anyError) {
        return;
      }
      this.$emit('changeP', this.changePassword)
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.logoutUser()
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      // this.changePassword.email = ''
      // this.changePassword.currentPassword = ''
      this.changePassword.newPassword = ''
    },

  },
}
</script>

<style lang="scss" scoped>
  .form-control1{
    margin-bottom: 10px;
  }
  input::-ms-reveal,
      input::-ms-clear {
        display: none;
      }
</style>
